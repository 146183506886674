import useAppState from 'utils/state'
import Icon from 'components/icon/icon'
import { cn } from 'utils/helpers'
import styles from './toast.module.scss'

export default function Toasts() {
    const { toast, hideToast } = useAppState()

    return (
        toast && (
            <div className={styles.container}>
                <div key={toast.id} className={cn(styles.toast, styles[toast.type])}>
                    <Icon icon={toast.type} className={styles.icon} />
                    <div>{toast.message}</div>
                    <Icon icon="x" className={styles.x} onClick={hideToast} />
                </div>
            </div>
        )
    )
}
